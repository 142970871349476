import axios from '@axios'

export default {
  namespaced: true,
  actions: {
    async importPayments({ commit }, fileData) {
      try {
        const formData = new FormData()
        formData.append('file', fileData.file)
        
        console.log('Enviando archivo:', fileData.file.name)
        
        const response = await axios.post('/api/v1/billing/payments/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        
        return response
        
      } catch (error) {
        console.error('Store: Error en la petición', error)
        throw error
      }
    }
  }
}