<template>
  <div>
    <!-- Formulario de importación -->
    <validation-observer 
      ref="importForm"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="importPayments">
        <b-card>
          <b-row>
            <b-col cols="12" md="4" lg="4">
              <b-form-group>
                <template v-slot:label>
                  Archivo <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Archivo"
                  rules="required"
                >
                  <b-form-file
                    id="file"
                    v-model="fileData.file"
                    accept=".xls, .xlsx"
                    placeholder="Seleccione un archivo"
                    drop-placeholder="Suéltelo aquí..."
                    browse-text="Buscar"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3" md="3" lg="3" class="mt-2">
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="invalid"
              >
                Importar
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="9" md="9" lg="9">
              <b-card-text>
                Archivo seleccionado: <strong>{{ fileData.file ? fileData.file.name : 'Ninguno' }}</strong>
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <!-- Tabla de resultados -->
    <div v-if="details.length > 0">
      <b-card>
        <b-row class="flex-row flex-nowrap">
          <b-col cols="12">
            <vue-double-scrollbar>
              <b-table
                :items="details"
                :fields="detailsColumns"
                class="text-center"
                striped
                thead-class="table-primary text-primary"
              >
                <template #cell(estado)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(data.item.estado)}`"
                  >
                    {{ data.item.estado }}
                  </b-badge>
                </template>
              </b-table>
            </vue-double-scrollbar>
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-button  
              variant="primary"
              @click="export2excel"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              <span class="text-nowrap">Exportar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile, BCard, BCardText, BRow, BCol,
  BButton, BForm, BFormGroup, BTable, BBadge
} from 'bootstrap-vue'
import * as XLSX from 'xlsx'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentsImportStore from './storeModule'

export default {
  components: {
    BRow, BCol, BFormFile, BCard, BCardText,
    BButton, BFormGroup, BForm, BTable, BBadge,
    ValidationProvider, ValidationObserver
  },
  
  data() {
    return {
      RESOURCES_ELEMENT_NAME: 'payments-import',
      ELEMENT_APP_STORE_MODULE_NAME: 'app-payments-import',
      fileData: {
        file: null
      },
      details: [],
      detailsColumns: [
        { key: 'numeroFactura', label: 'No. factura', sortable: true },
        { key: 'idTrabajador', label: 'Id del trabajador', sortable: true },
        { key: 'arl', label: 'ARL', sortable: true },
        { key: 'fechaRadicacion', label: 'Fecha radicación ARL', sortable: true },
        { key: 'radicadoArl', label: 'Radicado ARL', sortable: true },
        { key: 'estado', label: 'Estado', sortable: true },
        { key: 'error', label: 'Error', sortable: true }
      ],
      isSubmitting: false
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters[`${this.ELEMENT_APP_STORE_MODULE_NAME}/isLoading`]
    },
    storeError() {
      return this.$store.getters[`${this.ELEMENT_APP_STORE_MODULE_NAME}/error`]
    }
  },

  methods: {
    resolveStatusVariant(status) {
      return status === 'Importado' ? 'success' : 'danger'
    },

    async importPayments() {
      if (!this.fileData.file) return
      
      try {
        this.isSubmitting = true
        const response = await this.$store.dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/importPayments`, 
          this.fileData
        )
        
        this.details = response.data.details
        this.fileData.file = null
        this.$refs.importForm.reset()

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Importación exitosa',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Los pagos han sido importados correctamente',
          },
        })
      } catch (error) {
        console.error('Error en la importación:', error)
        if (error.response?.data) {
          this.details = error.response.data.details || []
        }
        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error en la importación',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response?.data?.message || 'Ha ocurrido un error al importar los pagos',
          },
        })
      } finally {
        this.isSubmitting = false
      }
    },

    async export2excel() {
      const filename = 'resultado_importacion_pagos'
      let data = this.details.map(item => ({
        'No. factura': item.numeroFactura,
        'Id del trabajador': item.idTrabajador,
        'ARL': item.arl,
        'Fecha radicación ARL': item.fechaRadicacion,
        'Radicado ARL': item.radicadoArl,
        'Estado': item.estado,
        'Error': item.error || ''
      }))

      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    }
  },

  mounted() {
    if (!this.$store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
      this.$store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, paymentsImportStore)
    }
  },

  unmounted() {
    if (this.$store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
      this.$store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    }
  }
}
</script>

<style scoped>
.scrollbar-container {
  overflow-y: auto;
  margin: 0 0;
}

.scrollbar-content {
  margin: 0 0;
}
</style>